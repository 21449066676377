import React from 'react';
import QRCode from 'react-qr-code';
import { string } from 'prop-types';

import { Box, Text, Center } from '@chakra-ui/react';
import useShiftTime from '@hooks/useShiftTime';

function QRCard({ name, code, desc, shift }) {
  const time = useShiftTime(shift);

  return (
    <Box>
      <Box>
        <Center>
          <QRCode value={code} size={140} />
        </Center>
      </Box>
      <Box marginTop="8px">
        <Text
          align="center"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          fontSize="10px"
        >
          {name}
        </Text>
        <Text align="center" fontSize="10px">{`Sesi ${shift} (${time})`}</Text>
        <Text align="center" fontSize="10px">{`${desc || '-'}`}</Text>
      </Box>
    </Box>
  );
}

QRCard.propTypes = {
  name: string.isRequired,
  desc: string.isRequired,
  code: string.isRequired,
  shift: string.isRequired,
};

export default React.memo(QRCard);
